import React, {useEffect, useState} from "react";
import loadable from '@loadable/component';
import './HomeSection.css'

const ScrollDown = loadable(() => import("../menu/scrollDown"));
const isWebpSupported = loadable(() => import("react-image-webp/dist/utils"));

const HomeSection=({post,inView,direction})=>{
    let bgImg = isWebpSupported?post.image.webp:post.image.jpg;
    const [style,setStyle]=useState({backgroundImage:`url(${bgImg})`});
        
    useEffect(()=>{
        if( direction==='up'){

            setStyle({backgroundImage:`url(${bgImg})`})
            setTimeout(()=>{setStyle({backgroundImage: 'none'})},800);
        }else if(direction==='down' && inView ){
            setStyle({backgroundImage:`url(${bgImg})`})
            setTimeout(()=>{setStyle({backgroundImage:`none`})},800);

        }else if(direction==='down down'){
            setStyle({backgroundImage:`none`})
        }
    },[direction,inView,post.image,bgImg])


    return  <section className="section" style={style}>
                {/* <a href={post.link} target="_blank"  rel="noopener noreferrer"> */}
                    <div className="section__wrapper" >
                        <div className="section__content go__wrapper">
                            <div className="section__details">
                                <div className="section__tag" >
                                    <h4 className="tag__title">{post.tag.name}</h4>
                                </div>
                                <h2 className="section__title"><a href={post.link} target="_blank"  rel="noopener noreferrer">{post.title}</a></h2>
                                <div className="section_hLine"/>
                                <p className="section__description" dangerouslySetInnerHTML={{__html:post.description}}/>
                            </div>
                        </div>
                    </div>
                {/* </a> */}
                <ScrollDown/>
            </section>
}
export default HomeSection;