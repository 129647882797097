import React from "react";
import './footer.component.css'
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../../assets/images/go-dgital-logo.svg'



const Footer = () => {
    return <footer className="footer">
        <div className="footer__wrapper go__wrapper">
            <div className="brand">
                <div className="brand__logo">
                    <div className="logo" >
                        <Logo />
                    </div>
                    <span className="logo__title"><span className="title--bold">Go </span> Digital. </span>
                </div>
                <div className="brand_slogan">
                    make <span className="slogan--bold"> it</span>
                </div>
            </div>
            <div className="menu">
                <div className="menu__wrapper">
                    <Link exact="true" to="/" className="menu__link">GO</Link>
                    <Link exact="true" to="/work" className="menu__link">Work</Link>
                    <Link to="/services" className="menu__link">Services</Link>
                    <Link to="/contact" className="menu__link">Contact</Link>
                    <Link to="/about" className="menu__link">About</Link>
                </div>
                <div className="copyright__wrapper">
                    © {(new Date().getFullYear())} Go Digital.
                </div>
            </div>
        </div>
    </footer>
}
export default Footer;