import React, { useContext } from 'react';
import loadable from '@loadable/component';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AppConfContext from "./utils/AppConfContext.util";
import HomePage from  './pages/home/Home.page';
import './App.css';

// const ScrollToTop = loadable(() => import("./components/router/ScrollToTop"))
const Menu = loadable(() => import("./components/menu/Menu.component"));
const AboutPage = loadable(() => import("./pages/about/About.page"));
const ServicePage = loadable(() => import("./pages/service/Service.page"));
const WorkPage = loadable(() => import("./pages/work/Work.page"));
const ContactPage = loadable(() => import("./pages/contact/Contact.page"));
const Footer = loadable(() => import("./components/footer/Footer.compenent"));
const SingleWork = loadable(() => import("./pages/work/single/SingleWork.page"));
const Helmet = loadable(() => import("react-helmet"));

function App() {
    const { showMenu } = useContext(AppConfContext);
    return (
        <Router>
            <Menu />
            <div className={showMenu ? 'content content--active' : 'content '}>
                <Switch>
                    <Route exact path="/">
                        <Helmet>
                            <title>Home | GoDigital | Digitize your Brand !</title>
                            <meta
                                name="description"
                                content="Go Digital is a marketing agency specialized in helping businesses transitioning into the digital world. Through website creation, graphic design, and other means!"
                            />
                            <script src="/logoAnimation.js" type="text/javascript" />
                        </Helmet>
                        <HomePage/>
                    </Route>
                    <Route  path="/services">
                        <Helmet>
                            <title>Services | GoDigital | Digitize your Brand !</title>
                            <meta
                                name="description"
                                content="Go Digital is a marketing agency specialized in helping businesses transitioning into the digital world. Through website creation, graphic design, and other means!"
                            />
                        </Helmet>
                        <ServicePage/>
                    </Route>
                    <Route  path="/work" exact>
                        <Helmet>
                            <title>Work | GoDigital | Digitize your Brand !</title>
                            <meta
                                name="description"
                                content="Go Digital is a marketing agency specialized in helping businesses transitioning into the digital world. Through website creation, graphic design, and other means!"
                            />
                        </Helmet>
                        <WorkPage/>
                    </Route>
                    <Route  path="/contact">
                        <Helmet>
                            <title>Contact Us | GoDigital | Digitize your Brand !</title>
                            <meta
                                name="description"
                                content="Go Digital is a marketing agency specialized in helping businesses transitioning into the digital world. Through website creation, graphic design, and other means!"
                            />
                        </Helmet>
                        <ContactPage/>
                    </Route>
                    <Route  path="/about">
                        <Helmet>
                            <title>About Us | GoDigital | Digitize your Brand !</title>
                            <meta
                                name="description"
                                content="Go Digital is a marketing agency specialized in helping businesses transitioning into the digital world. Through website creation, graphic design, and other means!"
                            />
                        </Helmet>
                        <AboutPage/>
                    </Route>
                    <Route path="/work/:_slug" component={SingleWork} />
                </Switch>
            </div>
            <Footer />
        </Router>
    );
}


export default App;
