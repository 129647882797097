import React, {useEffect, useRef} from "react";
import './Hero.css'
import {ReactComponent as LineSvg} from '../../assets/images/go-digital-hero-tag-line.svg'
import Logo from "../menu/Logo.component";

const Hero =({sectionIndex,api })=>{
    const scrollBtn = useRef(null);
    useEffect(() => {
    setTimeout(()=>{
        if(scrollBtn.current.getBoundingClientRect().top>20)
            scrollBtn.current.click();

        }, 3800);
    }, [])
    return <section className="section heroSection"  >
        <Logo sectionIndex={sectionIndex} />

        <button ref={scrollBtn} className="hero__scrollDown" onClick={() => api.moveSectionDown()}>
            <span className="hero_tag">
                Digital <span className="tag--bold">today,</span>
            </span>
            <div className="hero__scrollDownImg" >
                <LineSvg/>
            </div>
        </button>
    </section>
}
export default Hero
