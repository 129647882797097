import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage';
import {Posts} from '../../utils/data';

import HomeSection from "../../components/home/HomeSection.compenent";
import FooterSection from "../../components/home/FooterSection.compenent";
import useScroll from "../../utils/hooks/useScroll";
import Hero from "../../components/home/Hero.component";
import './Home.page.css'


const HomePage = () => {
   const {classNames,style,sectionTagNumber,onLeaveSlider,direction,sectionIndex} = useScroll();

   const renderSections = ({fullpageApi}) => {
        return (
        <ReactFullpage.Wrapper>
            <Hero sectionIndex={sectionIndex } api={fullpageApi}/>
            {Posts.map(post => {
                return <HomeSection key={post.tag.order} post={post} inView={sectionIndex === 1} direction={direction}/>
            })}
            <FooterSection/>
        </ReactFullpage.Wrapper>
        );
    }
    
    return <div className="fullPage__wrapper">
            <div className={classNames} style={style}/>
            <span className="tag__number">{sectionTagNumber}</span>
            <ReactFullpage scrollingSpeed={800} render={renderSections} onLeave={onLeaveSlider} />
        </div>
}
export default HomePage;