import { useState, useEffect,useContext } from "react";
import AppConfContext from "../AppConfContext.util";
import {Posts} from '../data';
import {isWebpSupported} from 'react-image-webp/dist/utils';

const useScroll = () => {

    const {sectionIndex, setIndex} = useContext(AppConfContext);
    const [sectionTagNumber, setTagNumber] = useState('01');
    const [direction, setDirection] = useState('down');
    const [classNames, setClassNames] = useState('fullPage__background');
    const [style, setStyle] = useState({backgroundImage: "none"});

    useEffect(() =>
    {
        (sectionIndex !== 0 && (sectionIndex - 1) !== Posts.length) ? setTagNumber(Posts[sectionIndex - 1].tag.order) : setTagNumber('');
        if (sectionIndex > 0) {
            if (direction === "down" && sectionIndex === 2) {
                setTimeout(() => {
                    setStyle({backgroundImage: `url(${isWebpSupported?Posts[sectionIndex - 1].image.webp:Posts[sectionIndex - 1].image.jpg})`});
                }, 200);
            }
            else
            if (direction === 'down' || direction === 'up') {
                setTimeout(() => {
                    setStyle({backgroundImage: `url(${isWebpSupported?Posts[sectionIndex - 1].image.webp:Posts[sectionIndex - 1].image.jpg})`});
                }, 750);
            }
            else if(Posts[sectionIndex - 1]){
                    setClassNames('fullPage__background fade-out');
                    setTimeout(() => {
                        setClassNames('fullPage__background');
                    }, 750);
                    setStyle({backgroundImage: `url(${isWebpSupported?Posts[sectionIndex - 1].image.webp:Posts[sectionIndex - 1].image.jpg})`});
            }
        }
        else {
            setStyle({backgroundImage: "none"});
        }

    }, [sectionIndex, direction]);

  const onLeaveSlider=(origin, destination, _) => {
        setIndex(destination.index);
        if (origin.index === 1 && destination.index === 0) {
            setDirection('up')
        } else
            if (origin.index === 0 && destination.index === 1) {
            setDirection('down');
        } else
            if (origin.index === 1 && destination.index === 2) {
            setDirection('down down');
        }
    }

  return {classNames,style,sectionTagNumber,onLeaveSlider,direction,sectionIndex};
};


export default useScroll;

