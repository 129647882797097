

import slide01 from '../assets/images/slider001.jpg';
import slide02 from '../assets/images/home/Insta.jpg';
import slide03 from '../assets/images/slider002.jpg';
import slide04 from '../assets/images/slider003.jpg';
import slide_ovh from '../assets/images/ovh_godigital.jpg';

import slide01webp from '../assets/images/slider001.webp';
import slide02webp from '../assets/images/home/Insta.webp';
import slide03webp from '../assets/images/slider002.webp';
import slide04webp from '../assets/images/slider003.webp';
import slide_ovhwebp from '../assets/images/ovh_godigital.webp';









import AASSlide1 from '../assets/images/projects/allAgadirSoussMassa/Sliders/App.png';
import AASSlide2 from '../assets/images/projects/allAgadirSoussMassa/Sliders/Web-Design-screens.png';
import AASSlide3 from '../assets/images/projects/allAgadirSoussMassa/Sliders/Brochure.png'
import AASSlide4 from '../assets/images/projects/allAgadirSoussMassa/Sliders/Gadgets.png';
import AASSlide1webp from '../assets/images/projects/allAgadirSoussMassa/Sliders/App.webp';
import AASSlide2webp from '../assets/images/projects/allAgadirSoussMassa/Sliders/Web-Design-screens.webp';
import AASSlide3webp from '../assets/images/projects/allAgadirSoussMassa/Sliders/Brochure.webp'
import AASSlide4webp from '../assets/images/projects/allAgadirSoussMassa/Sliders/Gadgets.webp';
import AllAgadirSoussMassaThambnail from "../assets/images/projects/allAgadirSoussMassa/THUMBNAIL.jpg";
import AllAgadirSoussMassaImage from "../assets/images/projects/allAgadirSoussMassa/mainImage.png";
import AllAgadirSoussMassaBag from "../assets/images/projects/allAgadirSoussMassa/bag.png";
import AllAgadirSoussMassaRollup from "../assets/images/projects/allAgadirSoussMassa/Roll_up.png";
import AllAgadirSoussMassaThambnailwebp from "../assets/images/projects/allAgadirSoussMassa/THUMBNAIL.webp";
import AllAgadirSoussMassaImagewebp from "../assets/images/projects/allAgadirSoussMassa/mainImage.webp";
import AllAgadirSoussMassaBagwebp from "../assets/images/projects/allAgadirSoussMassa/bag.webp";
import AllAgadirSoussMassaRollupwebp from "../assets/images/projects/allAgadirSoussMassa/Roll_up.webp";

import allASMVideoWorkPreview from '../assets/video/allAgadirSoussMassa/preview.mp4';


import LamedinaThambnail from "../assets/images/projects/lamedina/Thumbnail.jpg"
import LamedinaThambnailwebp from "../assets/images/projects/lamedina/Thumbnail.webp"
import LamedinaImage from "../assets/images/projects/lamedina/mainImage.png";
import LamedinaImagewebp from "../assets/images/projects/lamedina/mainImage.png";
import lamedinaBox from "../assets/images/projects/lamedina/image02.png";
import lamedinaTagine from "../assets/images/projects/lamedina/image01.png";
import lamedinaBoxwebp from "../assets/images/projects/lamedina/image02.webp";
import lamedinaTaginewebp from "../assets/images/projects/lamedina/image01.webp";
import lamedinaslide01 from "../assets/images/projects/lamedina/Sliders/images/slide_1.jpg";
import lamedinaslide01webp from "../assets/images/projects/lamedina/Sliders/images/slide_1.webp";
import lamedinaslide02 from "../assets/images/projects/lamedina/Sliders/images/slide_2.jpg";
import lamedinaslide02webp from "../assets/images/projects/lamedina/Sliders/images/slide_2.webp";
import lamedinaslide03 from "../assets/images/projects/lamedina/Sliders/images/slide_3.jpg";
import lamedinaslide03webp from "../assets/images/projects/lamedina/Sliders/images/slide_3.webp";
import lamedinaslide04 from "../assets/images/projects/lamedina/Sliders/images/slide_4.jpg";
import lamedinaslide04webp from "../assets/images/projects/lamedina/Sliders/images/slide_4.webp";
import lamedinaslide05 from "../assets/images/projects/lamedina/Sliders/images/slide_5.jpg";
import lamedinaslide05webp from "../assets/images/projects/lamedina/Sliders/images/slide_5.webp";
import lamedinaPreview from '../assets/video/lamedina/preview.mp4';


import doctoriThambnail from "../assets/images/projects/doctori/Thumbnail.jpg"
import doctoriThambnailwebp from "../assets/images/projects/doctori/Thumbnail.webp"
import doctoriImage from "../assets/images/projects/doctori/mainImage.png"
import doctoriStand from "../assets/images/projects/doctori/mobileApp.png";
import doctoriBag from "../assets/images/projects/doctori/webApp.png";
import doctoriStandwebp from "../assets/images/projects/doctori/mobileApp.webp";
import doctoriBagwebp from "../assets/images/projects/doctori/webApp.webp";
import doctoriImagewebp from "../assets/images/projects/doctori/mainImage.webp";
import doctoriPreview from '../assets/video/doctori/preview.mp4';




import MySchoolThambnail from "../assets/images/projects/myarcenciel/Thumbnail.jpg";
import MySchoolThambnailwebp from "../assets/images/projects/myarcenciel/Thumbnail.webp";
import MySchoolImage from "../assets/images/projects/myarcenciel/mainImage.png";
import MySchoolImagewebp from "../assets/images/projects/myarcenciel/mainImage.webp";
import MySchoolSlide1 from '../assets/images/projects/myarcenciel/sliders/slide_01.jpg';
import MySchoolSlide2 from '../assets/images/projects/myarcenciel/sliders/slide_02.jpg';
import MySchoolSlide3 from '../assets/images/projects/myarcenciel/sliders/slide_03.jpg';
import MySchoolSide1 from '../assets/images/projects/myarcenciel/side02.png';
import MySchoolSide2 from '../assets/images/projects/myarcenciel/side01.png';
import MySchoolSlide1webp from '../assets/images/projects/myarcenciel/sliders/slide_01.webp';
import MySchoolSlide2webp from '../assets/images/projects/myarcenciel/sliders/slide_02.webp';
import MySchoolSlide3webp from '../assets/images/projects/myarcenciel/sliders/slide_03.webp';
import MySchoolSide1webp from '../assets/images/projects/myarcenciel/side02.webp';
import MySchoolSide2webp from '../assets/images/projects/myarcenciel/side01.webp';
import InfluenceThambnail from "../assets/images/projects/influence/Thumbnail.jpg"
import InfluenceThambnailwebp from "../assets/images/projects/influence/Thumbnail.webp"
import InfluenceVideoWorkpreview from '../assets/video/influence/preview.mp4';
import InfluenceImage from "../assets/images/projects/influence/mainImage.png"
import InfluenceImagewebp from "../assets/images/projects/influence/mainImage.webp"


import InfluenceImageMobile from "../assets/images/projects/influence/mainImage_mobile.png"
import InfluenceImageMobilewebp from "../assets/images/projects/influence/mainImage_mobile.webp"
import InfluenceSide1 from '../assets/images/projects/influence/side1.png';
import InfluenceSide2 from '../assets/images/projects/influence/side2.png';
import InfluenceSide1webp from '../assets/images/projects/influence/side1.webp';
import InfluenceSide2webp from '../assets/images/projects/influence/side2.webp';


import InfluenceSlide1 from '../assets/images/projects/influence/sliders/images/slide1.jpg';
import InfluenceSlide2 from '../assets/images/projects/influence/sliders/images/slide2.jpg';
import InfluenceSlide3 from '../assets/images/projects/influence/sliders/images/slide3.jpg'; 
import InfluenceSlide1webp from '../assets/images/projects/influence/sliders/images/slide1.webp';
import InfluenceSlide2webp from '../assets/images/projects/influence/sliders/images/slide2.webp';
import InfluenceSlide3webp from '../assets/images/projects/influence/sliders/images/slide3.webp';


import shootWorkpreview from '../assets/video/shoot/preview.mp4';
import shootImage from "../assets/images/projects/shoot/mainImage.png";
import shootImagewebp from "../assets/images/projects/shoot/mainImage.webp";
import shootThambnail from "../assets/images/projects/shoot/Thumbnail.jpg"
import shootThambnailwebp from "../assets/images/projects/shoot/Thumbnail.webp"
import shootSide1 from '../assets/images/projects/shoot/side1.png';
import shootSide2 from '../assets/images/projects/shoot/side2.png';
import shootSide1webp from '../assets/images/projects/shoot/side1.webp';
import shootSide2webp from '../assets/images/projects/shoot/side2.webp';

import shootSlide1 from '../assets/images/projects/shoot/sliders/slide1.jpg';
import shootSlide2 from '../assets/images/projects/shoot/sliders/slide2.jpg';
import shootSlide3 from '../assets/images/projects/shoot/sliders/slide3.jpg';
import shootSlide4 from '../assets/images/projects/shoot/sliders/slide4.jpg';



import shootSlide1webp from '../assets/images/projects/shoot/sliders/slide1.webp';
import shootSlide2webp from '../assets/images/projects/shoot/sliders/slide2.webp';
import shootSlide3webp from '../assets/images/projects/shoot/sliders/slide3.webp';
import shootSlide4webp from '../assets/images/projects/shoot/sliders/slide4.webp';


import molokaiImage from "../assets/images/projects/molokai/mainImage.png";
import molokaiImagewebp from "../assets/images/projects/molokai/mainImage.webp";
import molokaiThambnail from "../assets/images/projects/molokai/Thumbnail.jpg"
import molokaiThambnailwebp from "../assets/images/projects/molokai/Thumbnail.webp"
import molokaiSide1 from '../assets/images/projects/molokai/side01.png';
import molokaiSide2 from '../assets/images/projects/molokai/side02.png';
import molokaiSide1webp from '../assets/images/projects/molokai/side01.webp';
import molokaiSide2webp from '../assets/images/projects/molokai/side02.webp';


import claviFoodImage from "../assets/images/projects/claviFood/mainImage.png";
import claviFoodImagewebp from "../assets/images/projects/claviFood/mainImage.webp";
import claviFoodThambnail from "../assets/images/projects/claviFood/Thumbnail.jpg"
import claviFoodThambnailwebp from "../assets/images/projects/claviFood/Thumbnail.webp"
import claviFoodSide1 from '../assets/images/projects/claviFood/side01.png';
import claviFoodSide2 from '../assets/images/projects/claviFood/side02.png';
import claviFoodSide1webp from '../assets/images/projects/claviFood/side01.webp';
import claviFoodSide2webp from '../assets/images/projects/claviFood/side02.webp';

import claviFoodSlide2 from '../assets/images/projects/claviFood/sliders/slide02.jpg';
import claviFoodSlide3 from '../assets/images/projects/claviFood/sliders/slide03.jpg';
import claviFoodSlide4 from '../assets/images/projects/claviFood/sliders/slide04.jpg';



import claviFoodSlide2webp from '../assets/images/projects/claviFood/sliders/slide02.webp';
import claviFoodSlide3webp from '../assets/images/projects/claviFood/sliders/slide03.webp';
import claviFoodSlide4webp from '../assets/images/projects/claviFood/sliders/slide04.webp';



import goBridgeWorkpreview from '../assets/video/gobridge/preview.mp4';
import goBridgeImage from "../assets/images/projects/goBridge/mainImage.png";
import goBridgeImagewebp from "../assets/images/projects/goBridge/mainImage.webp";
import goBridgeThambnail from "../assets/images/projects/goBridge/Thumbnail.jpg"
import goBridgeThambnailwebp from "../assets/images/projects/goBridge/Thumbnail.webp"
import goBridgeSide1 from '../assets/images/projects/goBridge/side01.png';
import goBridgeSide2 from '../assets/images/projects/goBridge/side02.png';
import goBridgeSide1webp from '../assets/images/projects/goBridge/side01.webp';
import goBridgeSide2webp from '../assets/images/projects/goBridge/side02.webp';

import goBridgeSlide1 from '../assets/images/projects/goBridge/sliders/slide01.jpg';
import goBridgeSlide2 from '../assets/images/projects/goBridge/sliders/slide02.jpg';
import goBridgeSlide3 from '../assets/images/projects/goBridge/sliders/slide03.jpg';
import goBridgeSlide4 from '../assets/images/projects/goBridge/sliders/slide04.jpg';
import goBridgeSlide5 from '../assets/images/projects/goBridge/sliders/slide05.jpg';



import goBridgeSlide1webp from '../assets/images/projects/goBridge/sliders/slide01.webp';
import goBridgeSlide2webp from '../assets/images/projects/goBridge/sliders/slide02.webp';
import goBridgeSlide3webp from '../assets/images/projects/goBridge/sliders/slide03.webp';
import goBridgeSlide4webp from '../assets/images/projects/goBridge/sliders/slide04.webp';
import goBridgeSlide5webp from '../assets/images/projects/goBridge/sliders/slide05.webp';

import goBridgeWork from '../assets/video/gobridge/work.mp4';
import shootWork from '../assets/video/shoot/work.mp4';
import InfluenceVideoWork from '../assets/video/influence/work.mp4';
import lamedinaVideoWork from '../assets/video/lamedina/work.mp4';
import allASMVideoWork from '../assets/video/allAgadirSoussMassa/work.mp4';
import doctoriVideoWork from '../assets/video/doctori/work.mp4';




export const Posts =[
    {
        tag:{
            order:"01",
            name:'Fire at ovh!'
        },
        title:'Internet in danger?',
        description:"Following the fire on March 9th that hit two of OVHcloud’s datacenters, several sites; French and foreign, have been made unreachable. Putting out of service over 3 million websites and businesses, including the WordPress image optimization site Imagify.",
        link:"https://www.journaldunet.com/web-tech/cloud/1498567-incendie-chez-ovh-3-6-millions-de-sites-web-sont-tombes/",
        image:{
            jpg:slide_ovh,
            webp:slide_ovhwebp
        }
    },
    {
        tag:{
            order:"02",
            name:'2024!'
        },
        title:'Digital Marketing trends in  2024',
        description:"In 2024, AI-driven personalization, short-form video, and social commerce are key trends. With a focus on first-party data and immersive experiences through AR and VR, brands are adapting to a rapidly evolving digital landscape.",
        link:"https://www.smartbrief.com/original/2020/12/4-major-trends-digital-marketing-2021",
        image:{
            jpg:slide01,
            webp:slide01webp
        }
    },
    {
        tag:{
            order:"03",
            name:'Brand marketing!'
        },
        title:'Brand marketing on Instagram.',
        description:"It's a memorable day for professionnal Instagram users. As the international picture sharing app announced that it is adding new branded content tags to help influencers grow their income.",
        link:"https://brandequity.economictimes.indiatimes.com/news/digital/instagram-adds-new-branded-content-capabilities-on-its-platform/79361518",
        image:{
            jpg:slide02,
            webp:slide02webp
        }
    },
    {
        tag:{
            order:"04",
            name:'Marketing!'
        },
        title:'Empathy & Marketing.',
        description:"Within the past year, customer behaviours in the face of a global pandemic greatly changed. Customers showed interest in brands showing empathy and responsive relationships with them.",
        link:"https://marketingtechnews.net/news/2020/nov/25/a-modern-approach-to-brand-building-empathy-storytelling-and-alignment/",
        image:{
            jpg:slide03,
            webp:slide03webp
        }
    },
    {
        tag:{
            order:"05",
            name:'Development!'
        },
        title:'Why everyone should learn Python.',
        description:"With the rise of online jobs, and tech related tasks, workers need to get accustomed to developer tools and learn a large range of abilities. According to Google and Youtube searches, Python related tutorials are one of the most searched queries on the names search engines.",
        link:"https://ia.acs.org.au/article/2020/the-most-self-taught-coding-language-right-now.html?utm_campaign=Frontend%2BWeekly&utm_medium=web&utm_source=Frontend_Weekly_229",
        image:{
            jpg:slide04,
            webp:slide04webp
        }
    },
]


export const Works =[

    {
        id:'all-agadir-souss-massa',
        title: 'All Agadir Souss Massa.',
        slug:'all-agadir-souss-massa',
        image:{
            jpg:AllAgadirSoussMassaThambnail,
            webp:AllAgadirSoussMassaThambnailwebp
        },
        tag:"Work",
        head:'Experience a new age of tourism.',
        details:{
            paragraph1:{
                content:'<strong>All Agadir Souss Massa  </strong> is an interactive platform that aims to put the citizens of Souss Massa at the heart of its tourism strategy. By involving them in the region’s touristic revival as well as its international reach.',
                background:{
                    png:AllAgadirSoussMassaImage,
                    webp:AllAgadirSoussMassaImagewebp
                }
            },
            paragraph2:{
                title:'Revitalize tourism.',
                content:'With an innovative approach, from its design to launch phase, GoDigital made All Agadir Souss Massa attain unprecedented results in both user base and public reception, reaching up to <storng>1500 users</storng> and <storng>+23000 followers</storng> on its launch night.',
            }
        },
        goals:{
            header_1:'Metrics:',
            header_2:'User base:',
            header_3:'All Agadir Souss Massa',
            header_4:'Regional Reach:',
            header_5:'Monthly users:',
            title:'In the span of its launch phase, All Agadir Souss Massa reached remarkable results.',
            goals_paragraph:'The platform aims to develop a network of ambassadors, who will be called upon to contribute to develop Souss Massa’s assets and tourist products.',
            background:{
                png:AllAgadirSoussMassaBag,
                webp:AllAgadirSoussMassaBagwebp
            },
            background2:{
                png:AllAgadirSoussMassaRollup,
                webp:AllAgadirSoussMassaRollupwebp
            },

            numberProject:'+23000',
            accessoriesCreated:'+2.000.000',
            packagingDesigned:'+750',
            paragraph:{
                title:'Longterm goals.',
                content:'All Agadir Souss Massa strives to centralize the region’s artistic, informative, and emotional content in a unique platform. It also aims to inspire its users (‘Embassadors’) to increase their contribution in their region as a whole, and in its touristic field post-COVID.'
            }

        },
        media:{
            video_url:allASMVideoWork,
            preview_url:allASMVideoWorkPreview
        },
        carousel:{
            slides:[
                {img:AASSlide1,imgwebp:AASSlide1webp},
                {img:AASSlide2,imgwebp:AASSlide2webp},
                {img:AASSlide3,imgwebp:AASSlide3webp},
                {img:AASSlide4,imgwebp:AASSlide4webp},
            ]
        },
        style:{
            colors:{
                primary:'#E8468F',
                secondary:'#E8468F'
            } ,
            banner:{
                    bgColor:"#0065f5",
                    bgColorBefore:"#E8468F",
                    bgColorBeforeTextColor:"#ffffff",
                    textColor:"#ffffff"
            },
            workDetails:{
                bgColor:"#0065f5",
                textColor:"#ffffff"
            }
        }

    },
    {
        id:'doctori',
        title: 'Doctori.',
        slug:'doctori',
        image:{
            jpg:doctoriThambnail,
            webp:doctoriThambnailwebp
        },
        tag:"Work",
        head:'Revolutionizing digital healthcare.',
        details:{
            paragraph1:{
                content:'<strong>Doctori.ma</strong> is a plateform that facilitates doctor/patient communication and removes all physical contact between health specialists and their patients. As well as giving doctors a large selection of tools to manage their practice, their consultations and schedules.',
                background:{
                    png:doctoriImage,
                    webp:doctoriImagewebp
                }
            },
            paragraph2:{
                title:'Streamlining management.',
                content:'The application not only focuses on patients, but also on doctors and their practices: With Doctori, we want to facilitate the agenda management process for doctors and lighten the workload for their secretaries. As well as accompany them in their digital transformation by offering them referencing tools.',
            }
        },
        goals:{
            header_1:'Metrics:',
            header_2:'User base:',
            header_3:'Online consultation',
            header_4:'National Reach:',
            header_5:'Monthly Patients:',
            title:'Doctori.ma has proven itself to be highly successful with its results.',
            goals_paragraph:'With the COVID-19 global pandemic, we added a Video consultation option to Doctori. It was also free during the lockdown period, as to encourage people to stay in their homes.',
            
            background:{
                png:doctoriBag,
                webp:doctoriBagwebp
            },
            background2:{
                png:doctoriStand,
                webp:doctoriStandwebp
            },
            numberProject:'7000 health specialists',
            accessoriesCreated:'+10.000.000',
            packagingDesigned:'+15.000',
            paragraph:{
                title:'Longterm goals.',
                content:'Doctori’s goal is to provide patients with a global database containing practitioners by city and speciality, to consult whenever and wherever they deem necessary. We plan to extend this database to the African market as a numerous patients and foreign doctors contacted us in order to join Doctori.ma.'
            }

        },
        media:{
            video_url:doctoriVideoWork,
            preview_url:doctoriPreview
        },
        
        style:{
            colors:{
                primary:'#2F9BA6',
                secondary:'#2F9BA6'
            } ,
            banner:{
                    bgColor:"#aa0f58",
                    bgColorBefore:"#F8FAFF",
                    bgColorBeforeTextColor:"#00000f",
                    textColor:"#fff"
            },
            workDetails:{
                bgColor:"#2F9BA6",
                textColor:"#ffffff"
            }
        }


    },
    {
        id:'lamedina',
        title: 'Médina de Coco Polizzi.',
        slug:'lamedina',
        image:{
            jpg:LamedinaThambnail,
            webp:LamedinaThambnailwebp
        },
        tag:"Work",
        head:'Modernizing tradition.',
        details:{
            paragraph1:{
                content:'Occupying an important position in the tourist and cultural landscape of the city, the medina of Agadir had an immense need for digitalisation. Go Digital was able to propose solutions to remedy this problem, and to make the place much more present on the internet.',
                background:{
                    png:LamedinaImage,
                    webp:LamedinaImagewebp
                }
            },
            paragraph2:{
                title:'Digitizing cultural heritage.',
                content:'By showcasing the medina’s craftsmen on a brand-new website, by shooting promotional videos around the place, and by providing an image bank of its buildings and the products offered, Go Digital has been able to induce modernity into the media used by the medina.',
            }
        },
        goals:{
            header_1:'Metrics:',
            header_2:'Post-production:',
            header_3:'Medina 2.0',
            header_4:'Development time:',
            header_5:'Pictures taken:',
            title:'At their launch, every communication support took up to:',
            goals_paragraph:'By setting up new media to be used in communication, and by producing an internet platform around the medina, we have managed to revitalise the image of the place and create new momentum.',
            
            background:{
                png:lamedinaTagine,
                webp:lamedinaTaginewebp
            },
            background2:{
                png:lamedinaBox,
                webp:lamedinaBoxwebp
            },
            numberProject:'110 hours',
            accessoriesCreated:'+200 hours',
            packagingDesigned:'+520 photos',
            paragraph:{
                title:'Results.',
                content:'Modernizing the supports used by the medina, and providing new ways and tools to promote the place have been our primary objectives.'
            }

        },
        media:{
            video_url:lamedinaVideoWork,
            preview_url:lamedinaPreview
        },
        carousel:{
            slides:[
                {img:lamedinaslide01,imgwebp:lamedinaslide01webp},
                {img:lamedinaslide02,imgwebp:lamedinaslide02webp},
                {img:lamedinaslide03,imgwebp:lamedinaslide03webp},
                {img:lamedinaslide04,imgwebp:lamedinaslide04webp},
                {img:lamedinaslide05,imgwebp:lamedinaslide05webp},
            ]
        },
        style:{
            colors:{
                primary:'#2F9BA6',
                secondary:'#2F9BA6'
            } ,
            banner:{
                    bgColor:"#481713",
                    bgColorBefore:"#ffe9d1",
                    bgColorBeforeTextColor:"#00000f",
                    textColor:"#ffffff"
            },
            workDetails:{
                bgColor:"#ffe9d1",
                textColor:"#00000f"
            }
        }


    },

    
    {
        id:'MySchool',
        title: 'MySchool.',
        slug:'MySchool',
        image:{
            jpg:MySchoolThambnail,
            webp:MySchoolThambnailwebp
        },
        tag:"Work",
        head:'Streamline e-learning processes.',
        details:{
            paragraph1:{
                content:'MySchool is a platform adopting a modern approach to educational management. The platform highlights tools aimed at making educational processes more fluid. The objective of MySchool is to facilitate access to the teachers’ list, their income, their students, and their classes. As well as to condense this information to present it in a clear and concise manner to avoid any confusion.',
                background:{
                    png:MySchoolImage,
                    webp:MySchoolImagewebp
                }
            },
            paragraph2:{
                title:'Efficiency in one click.',
                content:'We have also focused on providing the ultimate user experience! MySchool provides the possibility to schedule sessions, record lessons... And to centralize all useful functions in a single platform, without encumbering the users.',
            }
        },
        goals:{
            header_1:'Workload:',
            header_2:'',
            header_3:'Digitizing the education system ',
            header_4:'Operating costs reduction',
            header_5:'Deployment duration',
            title:'The development workload amounted to:.',
            goals_paragraph:'The platform allows students to receive reminder notifications before classes, to ensure that they do not forget the dates and times of their sessions. It also gives them the ability to access class replays through its automatic recording feature.',
            background:{
                png:MySchoolSide2,
                webp:MySchoolSide2webp
            },
            background2:{
                png:MySchoolSide1,
                webp:MySchoolSide1webp
            },

            numberProject:'+ 400 hours',
            accessoriesCreated:'Up to 30%.',
            packagingDesigned:'Less than 24 hours.',
            paragraph:{
                title:'Objectives.',
                content:'With the spread of online courses, and the large number of solutions all as complicated as each other, we wanted to offer an easy-to-use alternative with MySchool. While implementing tools that are as simple as they are effective.'
            }

        },
        carousel:{
            slides:[
                {img:MySchoolSlide1,imgwebp:MySchoolSlide1webp},
                {img:MySchoolSlide2,imgwebp:MySchoolSlide2webp},
                {img:MySchoolSlide3,imgwebp:MySchoolSlide3webp},
                // {img:MySchoolSlide4,imgwebp:MySchoolSlide4webp},

            ]
        },
        style:{
            colors:{
                primary:'#E8468F',
                secondary:'#E8468F'
            } ,
            banner:{
                    bgColor:"#1B243B",
                    bgColorBefore:"#FF7364",
                    bgColorBeforeTextColor:"#ffffff",
                    textColor:"#ffffff"
            },
            workDetails:{
                bgColor:"#1B243B",
                textColor:"#ffffff"
            }
        }

    },
    {
        id:'sm-photo-shoot',
        title: 'Souss Massa Photo Shoot.',
        slug:'sm-photo-shoot',
        image:{
            jpg:shootThambnail,
            webp:shootThambnailwebp
        },
        tag:"Work",
        head:'Depicting Uniqueness.',
        details:{
            paragraph1:{
                content:'To facilitate visual communication around the region, a photo shooting was carried out to set up a visual database of the Agadir Souss Massa tourist attractions. This database was accompanied by an easily accessible image bank.',
                background:{
                    png:shootImage,
                    webp:shootImagewebp
                }
            },
            paragraph2:{
                title:'Facilitating visual communication.',
                content:'This project had two objectives: To encourage communication around the region\'s unique sites and locations. And to give organizational actors ready-to-use photos in Agadir Souss Massa’s touristic promotion.',
            }
        },
        goals:{
            header_1:'Post-production',
            header_2:' we came out with:',
            header_3:'Sky’s the limit',
            header_4:'Workload:',
            header_5:'Metrics:',
            title:'After 750 hours of photo manipulations',
            goals_paragraph:'For a pre-approval of 250 photos, Go Digital exceeded this quota, and ended up offering 1,000 photos and 270 videos. Which was then presented in an image bank created by us.',
            
            background:{
                png:shootSide1,
                webp:shootSide1webp
            },
            background2:{
                png:shootSide2,
                webp:shootSide2webp
            },
            numberProject:'+1000 photos',
            accessoriesCreated:'+320 hours',
            packagingDesigned:'+26.000 unedited pictures.',
            paragraph:{
                title:'Aftermath.',
                content:'Ultimately, we created an easy-to-use tool for every stakeholder to find and use professionally made pictures. We modernized the visual communication process for the Regional Council of Tourism of Agadir Souss Massa.'
            }

        },
        media:{
            video_url:shootWork,
            preview_url:shootWorkpreview
        },
        carousel:{
            slides:[
                {img:shootSlide1,imgwebp:shootSlide1webp},
                {img:shootSlide2,imgwebp:shootSlide2webp},
                {img:shootSlide3,imgwebp:shootSlide3webp},
                {img:shootSlide4,imgwebp:shootSlide4webp},

            ]
        },
        style:{
            colors:{
                primary:'#2F9BA6',
                secondary:'#2F9BA6'
            } ,
            banner:{
                    bgColor:"#15A8DB",
                    bgColorBefore:"#B3EAFF",
                    bgColorBeforeTextColor:"#000000",
                    textColor:"#fff"
            },
            workDetails:{
                bgColor:"#15A8DB",
                textColor:"#ffffff"
            }
        }


    },
    {
        id:'influence',
        title: 'Influence Marketing.',
        slug:'influence',
        image:{
            jpg:InfluenceThambnail,
            webp:InfluenceThambnailwebp
        },
        tag:"Work",
        head:'Build up your credibility.',
        details:{
            paragraph1:{
                content:'In tandem with the Regional Council of Tourism of Agadir Souss Massa, Go Digital organized a digitalinfluence campaign in order to expand the region’s reach by inviting 13 local and foreign influencers todiscover Souss Massa.',
                background:{
                    png:InfluenceImage,
                    png_mobile:InfluenceImageMobile,
                    png_mobile_webp:InfluenceImageMobilewebp,
                    webp:InfluenceImagewebp
                }
            },
            paragraph2:{
                title:'Modernize the destination.',
                content:'Go   Digital   organized   an   influence   marketing   campaign   that   aimed   to   extend   the   reach   of   SoussMassa on social media, by inviting numerous influencers to discover the region. The objective was clear: Depict a young and modern region, receptive to its youth.',
            }
        },
        goals:{
            header_1:'Metrics:',
            header_2:'Total followers:',
            header_3:'Influence Marketing',
            header_4:'Reach:',
            header_5:'Likes:',
            title:'At the campaign’s conclusion the reached results were astounding:',
            goals_paragraph:'Go   Digital   took   charge   of   the   entire   operation:   from   planning   and   contacting   influencers,   to   the creation and publication of content during the campaign.',
            
            background:{
                png:InfluenceSide1,
                webp:InfluenceSide1webp
            },
            background2:{
                png:InfluenceSide2,
                webp:InfluenceSide2webp
            },
            numberProject:'+2640%',
            accessoriesCreated:'+52.500.000',
            packagingDesigned:'+1.200.000',
            paragraph:{
                title:'Aftermath.',
                content:'Over   3502   kilometres,   with   the   participation   of   13   influencers   such   as   "Anastasia   Ashley"   and "Hamada Chroukate", the influence campaign was a success both in terms of favourable opinions and in terms of reach, since at the end of this campaign, we’ve received more than 1500 messages.'
            }

        },
        media:{
            video_url:InfluenceVideoWork,
            preview_url:InfluenceVideoWorkpreview
        },
        carousel:{
                slides:[
                    {img:InfluenceSlide2,imgwebp:InfluenceSlide2webp},
                    {img:InfluenceSlide3,imgwebp:InfluenceSlide3webp},
		    {img:InfluenceSlide1,imgwebp:InfluenceSlide1webp}
                ]
        },
        style:{
            colors:{
                primary:'#2F9BA6',
                secondary:'#2F9BA6'
            } ,
            banner:{
                    bgColor:"#ba4557",
                    bgColorBefore:"#e45a5a",
                    bgColorBeforeTextColor:"#ffffff",
                    textColor:"#ffffff"
            },
            workDetails:{
                bgColor:"#ba4557",
                textColor:"#ffffff"
            }
        }


    },
    {
        id:'molokai',
        title: 'Molokaï Restaurant & Life.',
        slug:'molokai',
        image:{
            jpg:molokaiThambnail,
            webp:molokaiThambnailwebp
        },
        tag:"Work",
        head:'Reveal your culinary canvas.',
        details:{
            paragraph1:{
                content:'With Molokaï Restaurant & Life, we worked on a complete overhaul of the restaurant’s visual identity: Logo, flyers, posters...etc. Given Molokaï’s location (Libreville - Gabon) we had to get accustomed to the local food culture in order to design a culturally appropriate logo.',
                background:{
                    png:molokaiImage,
                    webp:molokaiImagewebp
                }
            },
            paragraph2:{
                title:'New foodie lifestyle.',
                content:'To coincide with its inauguration, we have prepared communication materials following the visual identity previously established by us. The primary objective of these supports was to promote the Molokaï establishment, to announce its opening to Libreville’s citizens, and to launch related pages on social networks.',
            }
        },
        goals:{
            header_1:'Metrics:',
            header_2:'Workload:',
            header_3:'Not just a restaurant, but a lifestyle',
            header_4:'Created content:',
            header_5:'Iterations made:',
            title:'The entire process of sketching, conception and graphic design took:',
            goals_paragraph:'With Molokaï, in our graphic creations we have expressed an idea that is not systematically linked to restoration: Lifestyle. This is all the more visible in the typography and the shapes used in the logo: Tangled curves that evoke a starry night sky.',
            
            background:{
                png:molokaiSide2,
                webp:molokaiSide2webp
            },
            background2:{
                png:molokaiSide1,
                webp:molokaiSide1webp
            },
            numberProject:'+ 34 days',
            accessoriesCreated:'4 weeks of daily content.',
            packagingDesigned:'x2 for each visual.',
            paragraph:{
                title:'Aftermath.',
                content:'Molokaï Restaurant & Life aims to be completely different from restaurants in Libreville by adopting aclear and distinct positioning compared to its competitors. And we took that goal to another level by proposing a renovation of every visual support the restaurant was planning to use. Which bore positive fruits as it increased Molokaï Restaurant & Life’s popularity.'
            }

        },
        media:null
        ,
        carousel:null
        ,
        style:{
            colors:{
                primary:'#2F9BA6',
                secondary:'#2F9BA6'
            } ,
            banner:{
                    bgColor:"#552C79",
                    bgColorBefore:"#77367B",
                    bgColorBeforeTextColor:"#ffffff",
                    textColor:"#ffffff"
            },
            workDetails:{
                bgColor:"#9a897c",
                textColor:"#ffffff"
            }
        }


    },
    {
        id:'claviFood',
        title: 'Calvi Food.',
        slug:'claviFood',
        image:{
            jpg:claviFoodThambnail,
            webp:claviFoodThambnailwebp
        },
        tag:"Work",
        head:'Take-away your taste buds.',
        details:{
            paragraph1:{
                content:'CalviFood is a project aimed at centralizing all the restoration offers in a given city. Go Digital tookcare of the design, development and implementation of a website to find restaurants according to location, type of food desired and price offers.',
                background:{
                    png:claviFoodImage,
                    webp:claviFoodImagewebp
                }
            },
            paragraph2:{
                title:'Find your food, fast.',
                content:'With CalviFood, we have privileged the site’s accessibility in addition to its speed. A user will be able to access a list of restaurants near his home in no more than 3 clicks. Speed and efficiency were our goals during our work.',
            }
        },
        goals:{
            header_1:'Metrics:',
            header_2:'We implemented up to :',
            header_3:'Launch event',
            header_4:'Monthly visitors',
            header_5:'Development time',
            title:'',
            goals_paragraph:'In addition to designing the application\'s website, Go Digital took care of the communication around CalviFood\'s first event: Burger Day. We created numerous visuals and communication media to help promote the CalviFood app.',
            
            background:{
                png:claviFoodSide1,
                webp:claviFoodSide1webp
            },
            background2:{
                png:claviFoodSide2,
                webp:claviFoodSide2webp
            },
            numberProject:'450 choices.',
            accessoriesCreated:'10.000',
            packagingDesigned:'+150 hours.',
            paragraph:{
                title:'Aftermath.',
                content:'With CalviFood, Go Digital was able to familiarize itself with the fast food industry in France. By working with the company, we were able to assimilate notions inherent to the sector, in addition to offering a different perspective from other French marketing agencies.'
            }

        },
        media:null,
        carousel:{
                slides:[
                    {img:claviFoodSlide2,imgwebp:claviFoodSlide2webp},
                    {img:claviFoodSlide3,imgwebp:claviFoodSlide3webp},
                    {img:claviFoodSlide4,imgwebp:claviFoodSlide4webp},
                ]
        },
        style:{
            colors:{
                primary:'#2F9BA6',
                secondary:'#2F9BA6'
            } ,
            banner:{
                    bgColor:"#3E1020",
                    bgColorBefore:"#BF275E",
                    bgColorBeforeTextColor:"#ffffff",
                    textColor:"#ffffff"
            },
            workDetails:{
                bgColor:"#BF275E",
                textColor:"#ffffff"
            }
        }


    },
    {
        id:'goBridge',
        title: 'Go Bridge.',
        slug:'goBridge',
        image:{
            jpg:goBridgeThambnail,
            webp:goBridgeThambnailwebp
        },
        tag:"Work",
        head:'Immigration with a capital I',
        details:{
            paragraph1:{
                content:'In our work with Go Bridge, we have collaborated on two distinct yet complementary axes: creating a visual identity (Logos, flyers, business cards...) then apply it in a website that acts as a registration platform. This site has two portals: one for studies in China, and the other for Canada. We also launched various types of content on social media with motion design videos and short snacking content to promote Go Bridge.',
                background:{
                    png:goBridgeImage,
                    webp:goBridgeImagewebp
                }
            },
            paragraph2:{
                title:'A virtual bridge to other countries.',
                content:'This platform allows those wishing to immigrate to; or study in China & Canada, to register in a list that will be consulted by Go Bridge advisors, who will contact them to answer their questions and suggest adjustments to their immigration file.',
            }
        },
        goals:{
            header_1:'Metrics:',
            header_2:'Go Bridge took up to :',
            header_3:'Students database',
            header_4:'Students',
            header_5:'Development time',
            title:'From the drafting phase to its launch ',
            goals_paragraph:'Our goal was to facilitate contact between those wishing to go abroad. We implemented a means to easily consult Go Bridge’s student’s database, containing information about them such as their email, phone number, address...etc.',
            
            background:{
                png:goBridgeSide1,
                webp:goBridgeSide1webp
            },
            background2:{
                png:goBridgeSide2,
                webp:goBridgeSide2webp
            },
            numberProject:'200 hours of work.',
            accessoriesCreated:'+ 150 in its first month.',
            packagingDesigned:'+150 hours.',
            paragraph:{
                title:'Results.',
                content:'We enabled Go Bridge to streamline its student contact and registration process, which was instrumental in easily managing the registrations of the numerous students who were interested in our client’s work thanks to the content published on the social networks.'
            }

        },
        media:{
            video_url:goBridgeWork,
            preview_url:goBridgeWorkpreview
        },
        carousel:{
                slides:[
                    {img:goBridgeSlide5,imgwebp:goBridgeSlide5webp},
                    {img:goBridgeSlide2,imgwebp:goBridgeSlide2webp},
                    {img:goBridgeSlide1,imgwebp:goBridgeSlide1webp},
                    {img:goBridgeSlide3,imgwebp:goBridgeSlide3webp},
                    {img:goBridgeSlide4,imgwebp:goBridgeSlide4webp},
                ]
        },
        style:{
            colors:{
                primary:'#2F9BA6',
                secondary:'#2F9BA6'
            } ,
            banner:{
                    bgColor:"#34b1b9",
                    bgColorBefore:"#202f6d ",
                    bgColorBeforeTextColor:"#ffffff",
                    textColor:"#ffffff"
            },
            workDetails:{
                bgColor:"#34b1b9",
                textColor:"#ffffff"
            }
        }


    },
   
    
   
    

]
export const Team=[
    {
        slug:'#',
        name:'Karim MSAAD',
        position:"CEO & Co-founder"
    } ,
    {
            slug:'#',
            name:'Ayoub MSAAD',
            position:"CEO & Co-founder"
    },
    {
        slug:'#',
        name:'Ayoub BENAIDA',
        position:"Content Manager"
     },
    {
        slug:'#',
        name:'Safia ELAADMI',
        position:"Sales manager"
    },

    {
        slug:'#',
        name:'Ayoub EL GHACHI',
        position:"Graphic and Print Designer"
    },
     {
            slug:'#',
            name:'Yassine BELFAKIH',
            position:"Motion Designer"
        },

    {
            slug:'#',
            name:'Abderrahim AGJIM',
            position:"Senior Frontend Developer"
        },
    {
        //https://chebaby.github.io/
        slug:'#',
        name:'Nour-Eddine ECH-CHEBABY',
        position:"Senior Backend Developer"
    },
    {
            slug:'#',
            name:'Hassan BENADARDOR',
            position:"Senior Fullstack Developer"
    },

    {
        slug:'#',
        name:'Youssef MSAAD',
        position:"Adviser"
    },
    {
        slug:'#',
        name:'Anouar EL KHEIRI',
        position:"Photographer"
    },
    {
        slug:'#',
        name:'Abdelbasset LEMSIHI',
        position:"Trainee"
    }
]
