import React, {useEffect, useState} from 'react';

import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppConfContext from "./utils/AppConfContext.util";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


const Index=()=>{
    useEffect(()=> {
        async function fetchData() {
             await import('./font.js');
        }
        fetchData();

    });
    const [sectionIndex,setIndex]=useState(0);
    const [showMenu,toggleShowMenu]=useState(false);
    return  <AppConfContext.Provider value={{showMenu,toggleShowMenu,sectionIndex,setIndex}}>
                    <App />
            </AppConfContext.Provider>
}

ReactDOM.render(<Index/>,document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

